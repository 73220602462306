<template>
  <v-container
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-card-title>
            Inadimplentes
            <v-spacer />
            <v-text-field
              v-model="nome"
              label="Buscar (nome)"
              single-line
              hide-details
              class="mr-5"
              @keyup.enter="load"
            />
            <v-text-field
              v-model="cpf"
              label="Buscar (cpf)"
              single-line
              hide-details
              class="mr-5"
              @keyup.enter="load"
            />
            <v-text-field
              v-model="codigoStyllus"
              label="Buscar (código styllus)"
              single-line
              hide-details
              type="number"
              @keyup.enter="load"
            />
            <v-btn
              color="primary"
              @click="load"
            >
              Buscar
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="clientes"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="footer_props"
            class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                >
                  <td>{{ item.nome }}</td>
                  <td>{{ item.codigoStyllus }}</td>
                  <td>{{ item.cpf }}</td>
                  <td>{{ item.contatoSMS }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.regiao }}</td>
                  <td>
                    {{ timestamp(item.dataDebitoMaisAntigo) }}
                    |
                    {{ formater.format(item.valorDebitoTotal) }}
                  </td>
                  <td>
                    <v-btn
                      color="success"
                      @click.stop="onGoAcordosList(item)"
                    >
                      Acordos
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <v-card-actions class="acoes d-print-none">
            <v-btn
              color="success"
              dark
              @click="download()"
            >
              Download
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import mime from 'mime-types'

  export default {
    name: 'UserList',
    data: () => ({
      nome: '',
      cpf: '',
      codigoStyllus: '',
      itemselected: {},
      clientes: [],
      headers: [
        { text: 'Nome', value: 'nome', sortable: true },
        { text: 'Código Styllus', value: 'cod', sortable: true },
        { text: 'CPF', value: 'cpf', sortable: true },
        { text: 'Cel', value: 'cel', sortable: true },
        { text: 'E-mail', value: 'email', sortable: true },
        { text: 'Região', value: 'regiao', sortable: true },
        { text: 'Débito mais antigo', value: 'maisantigo', sortable: true },
        { actions: '' },
      ],
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      total: 0,
      dialog: false,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
        SortAscending: true,
      },
      rules: [
        v => !!v || 'O novo valor é obrigatório',
      ],
      formater: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      id: 0,
    }),
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    methods: {
      load () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        this.filter.Nome = this.nome
        this.filter.CodigoStyllus = this.codigoStyllus
        this.filter.CPF = this.cpf
        this.filter.Inadimplente = true
        if (this.options.sortBy[0]) {
          this.filter.orderBy = this.options.sortBy[0]
          this.filter.sortDesc = this.options.sortDesc[0]
        }

        const query = JSON.stringify(this.filter)
        return this.$http.get('/cliente', { params: { query } })
          .then(resp => {
            this.clientes = resp.data.rows
            this.total = resp.data.total
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      onGoAcordosList (item) {
        this.$router.push(`/pages/inadimplentes/${item.id}/acordos/`)
      },
      timestamp (datestring) {
        return moment(datestring).format('DD/MM/YYYY HH:mm')
      },
      forceFileDownload (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const filename = Math.round((new Date()).getTime() / 1000)
        link.setAttribute('download', `${filename}.${mime.extension(response.headers['content-type'])}`)
        document.body.appendChild(link)
        link.click()
      },
      download () {
        const query = JSON.stringify(this.filter)
        this.$http.get('/clientes/inadimplentes/excel', { params: { query }, responseType: 'arraybuffer' })
          .then(response => {
            this.forceFileDownload(response)
          })
      },
    },
  }
</script>

<style>
.form-nome {
  width: 50%;
}
</style>
